YP.PricingExeperience = function() {

    var range            = $(".contact_input");

    const rangeInputs = document.querySelectorAll('input[type="range"]')

    function handleInputChange(e) {
        let target = e.target
        if (e.target.type !== 'range') {
            target = document.getElementById('range')
        } 
        const min = target.min
        const max = target.max
        const val = target.value
        
        target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
    }

    rangeInputs.forEach(input => {
        input.addEventListener('input', handleInputChange)
    })

    return {
        init: function() {
            range.on("change",              this.rangeChange);
        },
        
        rangeChange: function(){
            if( ($(range).length > 0) ) {
                if($(this).val() >= 5) {
                    var rangeData = $('.range_step_4');
                    var text        = rangeData.data('contacts') + "+";
                    var numberEmail = rangeData.data('mails') + "+";
                    var price       = 0;
                } else {
                    var rangeData = $('.range_step_' + $(this).val());
                    var text        = rangeData.data('contacts');
                    var numberEmail = rangeData.data('mails');
                    var price       = rangeData.data('price') + "€";
                }
                var offre       = $(this).val();    

                $('.offre').removeClass("active");
                $('.offre.offre_'+offre).addClass("active");

                $('.number').text(text);
                $('.number_email').text(numberEmail);
                $('.price').text(price);
            }
        }
    }
}

window.addEventListener("load", function(){
    YP.PricingExeperience().init();
});

YP.FaqAccordion = function() {
    var accordion = $(".accordion_title"),
    accordionFaq = $(".accordion_title_faq"),
    showFaq = $(".js-faq-show"),
    hideFaq = $(".js-faq-hide")
    ;

    accordion.on("click", function(e) {
        e.preventDefault();
        var $this = $(this);

        if (!$this.hasClass("active")) {
            $(".accordion_content").hide();
            $(".accordion_plus_icon").show();
        }

        $this.next().toggle();
        $('.accordion_plus_icon',this).toggle();
    });

    accordionFaq.on("click", function(e) {
        e.preventDefault();
        var $this = $(this);

        if (!$this.hasClass("active")) {
            $(".accordion_content_faq").hide();
            $(".accordion_plus_icon_faq").show();
        }

        $this.next().toggle();
        $('.accordion_plus_icon_faq',this).toggle();
    });

    showFaq.on("click", function(e) {
        e.preventDefault();
        hideFaq.toggle();

        if ($(this).text() == "En voir plus ↓")
        $(this).text("Réduire ↑")
        else
        $(this).text("En voir plus ↓");
    });

}

window.addEventListener("load", function () {
    YP.FaqAccordion();
});

YP.FeatureAccordion = function() {
    var accordion = $(".js-toggle");

    $(".js-toggle_content, .js-image-toggle").hide(); 
    $(".js-toggle_content:first, .js-image-toggle:first").slideDown(200); 
    $(".js-toggle:first").addClass("active"); 

    $(".js-toggle:first .js-image-toggle-icon").hide();

    accordion.on("click", function(e) {
        e.preventDefault();
        var $this = $(this);
        var target = $this.data('target');
        var $content = $('.js-toggle_content[data-target="' + target + '"]');
        var $image = $('.js-image-toggle[data-target="' + target + '"]');
        var $plusIcon = $this.find('.js-image-toggle-icon');

        if ($this.hasClass("active")) {
            return;
        }

        $(".js-toggle_content").slideUp(200);
        $(".js-image-toggle").slideUp(0);
        $(".js-image-toggle-icon").show();
        $(".js-toggle").removeClass("active");

        $content.slideDown(200);
        $image.slideDown(0);
        $plusIcon.hide();
        $this.addClass("active");
    });
}

window.addEventListener("load", function () {
    YP.FeatureAccordion();
});

YP.PricingPageManager = function() {

    var comPercent = 2 / 100,
        comFix = 0.59,
        incomePriceInput = $('#block-calcul-ticket-cost'),
        ypTicketingCost = $('#block-calcul-yp-ticket-cost'),
        ticketPriceInput = $('#block-calcul-input-price'),
        showPricingStandard= $('#showPricingStandard'),
        showPricingAdvanced= $('#showPricingAdvanced'),
        pricingStandard= $('#pricingStandard'),
        pricingAdvanced= $('#pricingAdvanced')
        ;

    return {
        init: function() {

            showPricingStandard.on('click', showBlocPricingStandard);
            showPricingAdvanced.on('click', showBlocPricingAdvanced);
            
            ticketPriceInput.on('input', updateFromTicketPriceInput);
            incomePriceInput.on('input', updateFromIncomePriceInput);

            function updateFromTicketPriceInput() {
                var ticketCost = parseFloat(ticketPriceInput.val());

                if (isNaN(ticketCost) || ticketCost === 0) {
                    incomePriceInput.val('0');
                    ypTicketingCost.html('0€');
                    return;
                }

                var cost = ticketCost * comPercent;
                if(cost < comFix) {
                    cost = comFix;
                }

                ypTicketingCost.html(cost.toFixed(2) + '€');

                if (ticketCost > 0) {
                    incomePriceInput.val((ticketCost - cost).toFixed(2));
                } else {
                    incomePriceInput.val('0');
                }
            }

            function updateFromIncomePriceInput() {
                var incomePrice = parseFloat(incomePriceInput.val());

                if (isNaN(incomePrice) || incomePrice === 0) {
                    ticketPriceInput.val('0');
                    ypTicketingCost.html('0€');
                    return;
                }

                var cost = incomePrice * comPercent;
                if(cost < comFix) {
                    cost = comFix;
                }

                ypTicketingCost.html(cost.toFixed(2) + '€');
                ticketPriceInput.val((incomePrice + cost).toFixed(2));
            }

            function showBlocPricingStandard() {
                pricingStandard.show();
                pricingAdvanced.hide();
                showPricingStandard.addClass("c-tab__item--active");
                showPricingAdvanced.removeClass("c-tab__item--active");
            }
    
            function showBlocPricingAdvanced() {
                pricingStandard.hide();
                pricingAdvanced.show();
                showPricingStandard.removeClass("c-tab__item--active");
                showPricingAdvanced.addClass("c-tab__item--active");
            }
        },
    }
}

window.addEventListener("load", function(){
    if($('#pricingPage').length > 0) {
        YP.PricingPageManager().init();
    }
});

YP.MenuDropdown = function() {

    var actionItem = $(".js-topnav--link");
    var linkList = $(".c-yf-topnav__list");
    var linkDropdown = $('.c-yf-topnav__list--link');
    var dropdown = $(".c-yf-topnav__list--dropdown");
    var mobile = $("#js-topnav--toggle");
    var mobileParticipant   = $(".c-header.t-header_nav_participant .c-header__more");

    return {
        init: function() {
            actionItem.on("click",           this.doShowDropdown);
            mobile.on("click",               this.doShowMenuMobile);
            mobileParticipant.on("click",    this.doShowMenuMobileParticipant);
            $(window).scroll(function() {
                if ($(window).scrollTop() >= 30) {
                    $('.c-yf-topnav--pro').css({
                      'border-bottom': '1px solid #F0F2F6'
                    });
                }
                if ($(window).scrollTop() < 30){
                    $('.c-yf-topnav--pro').css({
                        border: 'none'
                    });
                }
            })
        },
        doShowDropdown: function(e){

            var itemMenuDropdown = $(this).parent();

            if(itemMenuDropdown.hasClass('is-visible')){
                itemMenuDropdown.removeClass('is-visible');
                linkDropdown.removeClass('is-hidden');
                dropdown.removeClass('is-visible');
            }
            else{
                actionItem.each(function(){
                    $(this).removeClass('is-visible');
                });
                itemMenuDropdown.addClass('is-visible');
                linkDropdown.addClass('is-hidden');
                dropdown.addClass('is-visible');
            }

        },
        doShowMenuMobile: function(e){
            e.preventDefault();

            var itemMenuDropdown2 = $(".c-yf-topnav");
            if($(this).hasClass('is-active')){
                $(this).removeClass('is-active');
                linkList.removeClass('is-active');
                $(itemMenuDropdown2).removeClass('is-active');
                $(".body-layout-pro").css({
                    'overflow': 'auto',
                    'position': 'inherit'
                });
            }
            else{
                $(this).addClass('is-active');
                linkList.addClass('is-active');
                $(itemMenuDropdown2).addClass('is-active');
                $(".body-layout-pro").css({
                    'overflow': 'hidden',
                    'position': 'fixed'
                });
            }

        },
        doShowMenuMobileParticipant: function(e){
            e.preventDefault();

            var itemMenuDropdown2 = $(".t-header_nav_participant .container_height");

            if($(itemMenuDropdown2).hasClass('is-active')){
                $(itemMenuDropdown2).removeClass('is-active');
            }
            else{
                $(itemMenuDropdown2).addClass('is-active');
            }

        }
    }
}

window.addEventListener("load", function(){
    YP.MenuDropdown().init();
});

$("#search").focusin(function() {
    $("#search-btn").css('display', "block");
});

$(".js-open-modal").click(function(){
    $(".c-modal--yf").addClass("visible");
});
  
$(".js-close-modal").click(function(){
    $(".c-modal--yf").removeClass("visible");
});
  
$(document).click(function(event) {
    if (!$(event.target).closest(".modal,.js-open-modal").length) {
        $("body").find(".c-modal--yf").removeClass("visible");
        $(".c-modal--yf iframe").attr("src", $(".c-modal--yf iframe").attr("src"));
    }
});

var videoAinterexpo = $('#js-video-ainterexpo #js-video-ainterexpo-iframe').attr('src');
$('#js-video-ainterexpo img').on("click", function(e) {
    $('#js-video-ainterexpo #js-video-ainterexpo-iframe').attr('src', `${videoAinterexpo}&autoplay=1`);
    $(this).hide();
    $("#js-video-ainterexpo #js-video-ainterexpo-iframe").show();
});

var videoHpcom = $('#js-video-hpcom #js-video-hpcom-iframe').attr('src');
$('#js-video-hpcom img').on("click", function(e) {
    $('#js-video-hpcom #js-video-hpcom-iframe').attr('src', `${videoHpcom}&autoplay=1`);
    $(this).hide();
    $("#js-video-hpcom #js-video-hpcom-iframe").show();
});

var videoIvanhoe = $('#js-video-ivanhoe #js-video-ivanhoe-iframe').attr('src');
$('#js-video-ivanhoe img').on("click", function(e) {
    $('#js-video-ivanhoe #js-video-ivanhoe-iframe').attr('src', `${videoIvanhoe}&autoplay=1`);
    $(this).hide();
    $("#js-video-ivanhoe #js-video-ivanhoe-iframe").show();
});
